import { SENTIMENT_ACTION } from "./actions";

const COLLECTION_NAME = "NS_WEB_POPUP";
interface IXUData {
  popups: any[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
}

const initialState: IXUData = {
    popups: [],
  requested: false,
  loaded: false,
  lastUpdate: 0
};

export function loadPopups(firebase: any) {
 
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Popup.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.POPUP,
      payload: { requested: true, loaded: false, popups: [] },
    });


    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}`)
      .orderBy("dateTime", "desc")
      .limit(1)
      .onSnapshot((querySnapshot: any) => {

        var popups: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
            popups.push({
                ...doc.data(),
                dateTime: doc.data().dateTime.toDate()
            });
        });

        dispatch({
          type: SENTIMENT_ACTION.POPUP,
          payload: {
            popups,
            loaded: true,
            requested: true,
            lastUpdate: new Date().getTime()
          },
        });
      });
  };
}

export default function Popup(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.POPUP:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const popupSelector = (state: any) => state.Popup;
