import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";

export const firebaseConfig = {
  apiKey: "AIzaSyB_DadiPKlPLIt3sw_uPFr6i3KxI1WDiIs",
  authDomain: "algo-senti.firebaseapp.com",
  databaseURL: "https://algo-senti.firebaseio.com",
  projectId: "algo-senti",
  storageBucket: "algo-senti.appspot.com",
  messagingSenderId: "109562175983",
  appId: "1:109562175983:web:66ed44b4c1feae44dbd61a",
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const fireStore = firebaseApp.firestore();
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage()

export const firebaseConfig2 = {
  apiKey: "AIzaSyAKWXm7Z0xL_e7TuOvKb8Bbr6haRokzAPI",
  authDomain: "dinamik-zephlex.firebaseapp.com",
  databaseURL: "https://dinamik-zephlex-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dinamik-zephlex",
  storageBucket: "dinamik-zephlex.firebasestorage.app",
  messagingSenderId: "875563219822",
  appId: "1:875563219822:web:09223dc06909037c531f7f"
};

export const firebaseApp2 = firebase.initializeApp(firebaseConfig2, "RealtimeApp");
export const realtimeDatabase = firebaseApp2.database()