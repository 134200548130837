import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadPopups,
  popupSelector,
} from "../../appRedux/reducers/sentiment/popup";
import moment from "moment";
import { firebaseApp } from "../../firebase";
import { Modal } from "antd";

export const Popup = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState<any>(null);
  useEffect(() => {
    dispatch(loadPopups(firebaseApp));
  }, []);

  const popups = useSelector(popupSelector);

  useEffect(() => {
    try {
      if (popups.popups?.length > 0 && !isNaN(popups.popups[0].dateTime.getTime())) {
        if (!localStorage) return;
        let lastPopupDateTime = localStorage
          ? localStorage.getItem("lastPopupDateTime")
          : 0;
        if (
          lastPopupDateTime &&
          lastPopupDateTime != "" &&
          !isNaN(Number(lastPopupDateTime)) &&
          lastPopupDateTime != "0"
        ) {
          if (Number(lastPopupDateTime) > popups.popups[0].dateTime.getTime()) {
            return;
          }
        }

        localStorage.setItem(
          "lastPopupDateTime",
          new Date().getTime().toString()
        );
        setActive(popups.popups[0]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [popups]);

  return (
    <Modal
      open={!!active}
      onCancel={() => {
        setActive(null);
      }}
      onOk={() => {
        window.open(active?.link, "_blank");
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <h1>{active?.title}</h1>
        <img src={active?.image} alt={active?.title} />
        <p>{active?.content}</p>
        <a href={active?.link} target="_blank">
          {active?.link}
        </a>
      </div>
    </Modal>
  );
};
