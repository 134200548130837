import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  stockAlarmStateSelector,
  loadStockAlarmState,
} from "../../../appRedux/reducers/sentiment/stock-alarm-state";
import { firebaseApp } from "../../../firebase";
import { toast } from "react-toastify";
import { useAuth } from "../../../authentication";
import { Modal, Tag } from "antd";
import useSound from "use-sound";
import beepSound from "../../../assets/sounds/beep.mp3";

export const Alarm = () => {
  const [play] = useSound(beepSound);

  const dispatch = useDispatch();
  const [alarms, setAlarms] = useState<any>([]);
  const [showAlarmModal, setShowAlarmModal] = useState(false);
  const [displayAlarm, setDisplayAlarm] = useState<any>(null);
  const [initialAlarms, setInitialAlarms] = useState<boolean>(false);
  const { authUser } = useAuth();

  useEffect(() => {
    if (authUser) dispatch(loadStockAlarmState(firebaseApp, authUser.uid));
  }, []);
  const alarmData = useSelector(stockAlarmStateSelector);
  const alarmStates = alarmData.alarms;
  const createNotification = (alarms: any) => {
    if (!alarms || alarms.length === 0) return;
    let name = "";
    if (alarms.length === 1) name = alarms[0].name;
    else if (alarms.length > 1) {
      name = alarms.map((alarm: any) => alarm.name).join(", ");
    }
    if ("Notification" in window && Notification.permission === "granted") {
      const notification = new Notification(`Sentiment Algo`, {
        body: `Alarm "${name}" güncellendi`,
      });
      notification.onclick = function (event) {
        event.preventDefault(); // prevent the browser from focusing the Notification's tab
        window.open("https://sentimentalgo.com/alarms", "_blank");
      };
    }
    toast.info(`Alarm "${name}" güncellendi.`, {
      autoClose: 5000,
      position: "bottom-right",
      hideProgressBar: true,
      closeOnClick: true,
      onClick: () => {
        setDisplayAlarm(alarms[0]);
        setShowAlarmModal(true);
      },
    });
    play();
  };
  useEffect(() => {
    if (!alarmData.loaded) return;
    if (initialAlarms === false) {
      setAlarms(alarmStates);
      setInitialAlarms(true);
      return;
    }
    let alarmForNotification = [];

    for (let i = 0; i < alarmStates?.length; i++) {
      const id = alarmStates[i].id;
      const oldAlarm = alarms.find((alarm: any) => alarm.id === id);
      if (!oldAlarm) {
        alarmForNotification.push(alarmStates[i]);

        continue;
      } else {
        if (oldAlarm.symbols.length !== alarmStates[i].symbols.length) {
          alarmForNotification.push(alarmStates[i]);
          continue;
        }
        for (let j = 0; j < alarmStates[i].symbols.length; j++) {
          if (!oldAlarm.symbols.includes(alarmStates[i].symbols[j])) {
            alarmForNotification.push(alarmStates[i]);
            break;
          }
        }
      }
    }
    if (alarmForNotification.length > 0) {
      createNotification(alarmForNotification);
    }
    setAlarms(alarmStates);
  }, [alarmStates]);
  return showAlarmModal ? (
    <Modal
      title="Alarm Detayı"
      open={showAlarmModal}
      onOk={() => setShowAlarmModal(false)}
      onCancel={() => setShowAlarmModal(false)}
      okText="Tamam"
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <div>
        <h3>{displayAlarm.name}</h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            alignItems: "flex-start",
          }}
        >
          <div>Semboller</div>
          <div>
            {displayAlarm?.symbols.map((symbol: string) => {
              return (
                <Tag
                  color={"geekblue"}
                  style={{ cursor: "pointer" }}
                  key={symbol}
                >
                  {symbol}
                </Tag>
              );
            })}
          </div>
          <div>Girenler</div>
          <div>
            {displayAlarm?.incoming.map((symbol: string) => {
              return (
                <Tag color={"green"} style={{ cursor: "pointer" }} key={symbol}>
                  {symbol}
                </Tag>
              );
            })}
          </div>
          <div>Çıkanlar</div>
          <div>
            {displayAlarm?.outgoing.map((symbol: string) => {
              return (
                <Tag
                  color={"volcano"}
                  style={{ cursor: "pointer" }}
                  key={symbol}
                >
                  {symbol}
                </Tag>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  ) : null;
};